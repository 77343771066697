* {
    box-sizing: border-box;
}

.main-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

body {
    margin: 50px;
    font-family: Open Sans;
    font-weight: medium;
    height: 100%;
}

h1  {
    font-size: 28px;
}

.test-progress {
    position: fixed;
    height: 7px;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
}

.exam-progress {
    position: absolute;
    width: 100%;
    height: 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
}

.exam-progress-container {
    height: 7px;
}

.exam-progress[value]::-webkit-progress-bar {
    border-radius: 8px 8px 0 0;
}

.exam-progress[value]::-webkit-progress-value {
    border-radius: 8px 0 0 0;
}

.exam-progress.full[value]::-webkit-progress-value {
    border-radius: 8px 8px 0 0;
}

.card-container {
    position: relative;
    width: 100%;
    cursor: pointer;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
}

.card {
    padding: 10px;
}

[data-theme='light'] .card-container {
    background-color: #F8F8F8;
}

[data-theme='light'] .card-container:hover {
    background-color: #ffffff;
}

[data-theme='dark'] .card-container {
    background-color: #222222;
}

[data-theme='dark'] .card-container:hover {
    background-color: #2e2e2e;
}

.card h2 {
    margin: 0;
    font-size: 18px;
}

.red,
.yellow,
.green {
    display: inline-block;
    font-weight: bold;
}

.flex-space {
    display: flex;
    justify-content: space-between;
}

.clickable {
    cursor: pointer;
}

.answer-incorrect,
.answer-correct,
.new {
    display: inline;
}

.logo {
    width: 96px;
    height: 96px;
}

.test-passed-icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.modal {
    position: absolute;
    inset: 250px;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

[data-theme='light'] body,
[data-theme='light'] .modal {
    background-color: #f8f9fa;
    color: #000000;
}

[data-theme='dark'] body,
[data-theme='dark'] .modal {
    background-color: #202020;
    color: #ffffff;
}

[data-theme='dark'] a {
    color: #8ab4f8;
}

[data-theme='dark'] a:visited {
    color: #c58af9;
}

img {
    max-width: 100%;
    max-height: 40vh;
}

ul {
    padding-inline-start: 20px;
}

.flex-center {
    display: flex;
    align-items: center;
}

.passed-icon {
    height: 24px;
    margin-right: 10px;
}

.spacing {
    height: 20px;
}

.copyright {
    color: #aaaaaa;
    margin-top: 50px;
}

.copyright a {
    text-decoration: underline;
    color: #aaaaaa !important;
}

.question label {
    display: block;
    cursor: pointer;
    font-size: 24px;
    margin: 10px 0;
    user-select: none;
}

.report label {
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0;
    user-select: none;
}

.input-bar {
    width: 250px;
    height: 30px;
    font-size: 20px;
    border-radius: 4px;
    padding: 10px;
}

.question input[type=radio] {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    cursor: pointer;
}

.report input[type=radio] {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0;
    margin-right: 10px;
}

textarea {
    box-sizing: border-box;
    width: 100%;
    display: block;
    font-size: 16px;
    padding: 10px;
    margin-top: 20px;
    resize: none;
    font-family: sans-serif;
}

.monospace {
    font-family: monospace;
    font-size: 16px;
}

.answer label,
.settings label {
    display: block;
    cursor: pointer;
    font-size: 18px;
    margin: 10px 0;
    user-select: none;
}

.answer input[type=checkbox],
.settings input[type=checkbox] {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    cursor: pointer;
}

.margin-top {
    margin-top: 10px;
}

button {
    margin-right: 10px;
    margin-top: 20px;
    width: 200px;
    height: 50px;
    border: 0;
    border-radius: 4px;
    font-weight: 700;
    cursor: pointer;
}

[data-theme='light'] button {
    background-color: #000000;
    color: #ffffff;
}

[data-theme='dark'] button {
    background-color: #303030;
    color: #ffffff;
}

button.cancel {
    border: 2px #000000 solid;
    background-color: #ffffff;
    color: #000000;
}

button:disabled {
    opacity: 0.4;
    cursor: default;
}

[data-theme='light'] .yellow {
    color: goldenrod;
}

[data-theme='light'] .warning,
[data-theme='light'] .answer-incorrect,
[data-theme='light'] .red {
    color: crimson;
}

[data-theme='light'] span,
[data-theme='light'] .success,
[data-theme='light'] .answer-correct,
[data-theme='light'] .new,
[data-theme='light'] .green {
    color: green;
}

[data-theme='dark'] .yellow {
    color: yellow;
}

[data-theme='dark'] .warning,
[data-theme='dark'] .answer-incorrect,
[data-theme='dark'] .red {
    color: pink;
}

[data-theme='dark'] span,
[data-theme='dark'] .success,
[data-theme='dark'] .answer-correct,
[data-theme='dark'] .new,
[data-theme='dark'] .green {
    color: lightgreen;
}

.test-progress[value]::-webkit-progress-value {
    background-color: lightgreen;
}

.exam-progress[value]::-webkit-progress-value {
    background-color: lightskyblue;
}

.exam-progress.full[value]::-webkit-progress-value {
    background-color: lightgreen;
}

span {
    font-weight: bold;
    text-decoration: underline;
}

.no-margin {
    margin: 0;
    font-weight: normal;
}

.icon-container,
.report-option,
.report-header {
    display: flex;
    align-items: center;
}

.icon {
    float: left;
    margin-right: 10px;
}

[data-theme='light'] .subtext {
    color: #7d7d7d;
}

[data-theme='dark'] .subtext {
    color: #aaaaaa;
}

@media (max-width: 768px) {
    button {
        width: 100%;
    }
}

@media (max-width: 992px) {
    body {
        margin: 20px;
    }

    .question label {
        font-size: 20px;
    }

    .question input[type=radio] {
        width: 20px;
        height: 20px;
    }

    .answer label,
    .settings label {
        font-size: 16px;
    }

    .answer input[type=checkbox],
    .settings input[type=checkbox] {
        width: 16px;
        height: 16px;
    }

    h2 {
        font-size: 22px;
    }
}